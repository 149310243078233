nav  {
    background-color: #0396a6e3;
    position:fixed;
    width:100%;
    display: inline;
    top:0;


    & ul{
        width:90%;
        display: inline-block;
        box-sizing: border-box;
        li {

            display: inline-block;
            margin: 0 10px;
            padding: 10px;
            cursor: pointer;
            width:15%;
            &:hover{
                font-weight: bold;
            }
            & a {
                text-decoration: none;
                color:white;
            }
        }  
    }
    & .idioma {
        width: 10%;
        display: inline-block;
        border:none;
        background-color: transparent;
        color:white;
        cursor: pointer;
        &:hover{
            font-weight: bold;
        }
        
    }
}